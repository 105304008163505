export const environment = {
  production: true,
  endpoint: `https://stp2019api.stpehs.com/api/`, // production
  authURL: `https://auth.stpehs.com/audithub/login/`, //production
  PROTOCOL_VIRTUAL_DIRECTORY: 'publications/',
  profileApiKey:'bac1fedabe9649e88285218640ce0504',
  cookieIdleMultiTab: 'activeAt',
  token:'STPHUB_TOKEN',
  companyName:'COMPANY_Name',
  DOMAIN: `.stpehs.com`,
  StpDomain: `https://www.stpub.com`,
  AudithubDomain: `https://audithub.stpehs.com`,
  RegHubDomain: 'https://reghub.stpehs.com',
  secretKey: `093425346907875356244547`,
  version: `1.0.74a`
};